/* SideDrawer.css */
.side-drawer {
  position: fixed;
  top: 0;
  left: -300px; /* Start off-screen */
  width: 250px;
  height: 100%;
  background: #0b172f;
  z-index: 100;
  transition: left 0.3s ease-in-out;
}

.side-drawer.open {
  left: 0; /* Slide in when open */
}

nav ul {
  list-style: none;
  padding: 0;
}

nav ul li {
  padding: 10px 20px;
  font-size: 18px;
  color: #fff;
  cursor: "pointer";
}
.close-div {
  margin: 14px auto;
  border-radius: 100%;
  background: #fff;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.close-div > svg {
  color: #2669f5;
}

@media (min-width: 992px) and (max-width: 1200px) {
  nav ul li {
    padding: 10px 15px;
  }
}
