.App {
    font-family:'Poppins';
    text-align: center;
  }
p{
  margin: 0;
}
  body {
    left: 0;
    top: 0;
    margin: 0;
    padding: 0;
    overflow: inherit !important; 
    padding-right: 0px !important;
  }
  
  .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.03) !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .heading1 {
    color: #0d8ccd;
    font-size: 16px;
    background-color: #000;
    display: flex;
    justify-content: center;
  }
  
  .textbox h1 {
    color: red;
  }
  .textbox h1:hover {
    color: black;
  }
  
  .sociaLinks li {
    margin: 0 5px 0 0;
    padding-left: 0;
  }
  .sociaLinks li a {
    margin: 0 12px 0 0;
  }
  
  .listingFooter li {
    padding: 2px 0;
    line-height: 24px;
  }
  .listingFooter li a {
    padding: 3px 0;
    font-weight: 400;
    font-family: "Inter";
    font-size: 16px;
    color: rgb(78, 84, 123);
  }
  .listingFooter li a:hover {
    color: rgb(53, 109, 223);
  }

  
  .MuiDialogActions-root {
    flex: 0 0 auto;
    display: flex;
    padding: 8px;
    align-items: center;
    justify-content: center !important;
  }
  
  .moveTop {
    -webkit-animation: moveTop 5s normal linear infinite;
    animation: moveTop 5s normal linear infinite;
  }
  
  @-webkit-keyframes moveTop {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  @keyframes moveTop {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  .moveLeft {
    -webkit-animation: moveLeft 5s normal linear infinite;
    animation: moveLeft 5s normal linear infinite;
  }
  
  @-webkit-keyframes moveLeft {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-20px);
    }
    100% {
      transform: translateX(0px);
    }
  }
  @keyframes moveLeft {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-20px);
    }
    100% {
      transform: translateX(0px);
    }
  }
  @media (max-width: 1466px) {
    .signupBox {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
  @media (max-width: 767px) {
    .signupBox {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
  
  
  .MuiDialogContent-root {
    flex: 1 1 auto;
    padding: 8px 24px;
    overflow-y: hidden !important;
  }
  .table-scroll::-webkit-scrollbar {
    display: block;
    width: 10px;
    height: 10px;
  } /*# sourceMappingURL=main.css.map */
  .table-scroll::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  .table-scroll::-webkit-scrollbar-thumb {
    background: #888; 
  }
  .table-scroll::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  
  .teamDropdown {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    color: #3e3e3e;
  }
  .teamDropdownBuy {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 21px */
  
    text-align: center;
  
    color: #3e3e3e;
  }
  /* .drawerimg {
    background-image: url("../scss/dashBackground 2.png");
  } */
  .footerText {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin: 0 !important;
    text-decoration: none !important;
    margin-top: 9px !important;
  }
  .footerText,
  a:link,
  a:visited {
    text-decoration: none;
  }
  .pt-60 {
    padding-top: 60px !important;
    padding-bottom: 47px;
  }
  .pt-100 {
    margin-top: 97px;
  }
  @media only screen and (max-width: 959px) {
    .pt-100 {
      margin-top: 0px;
    }
  }
  .followText {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    /* identical to box height */
    margin-bottom: 0 !important;
    color: #000000;
  }
  .pr-26 {
    padding-right: 32px !important;
    padding-bottom: 98px;
    padding-top: 22px;
  }
  .founderDiv {
    padding: 91px 29px 0 29px;
  }
  .switchCustom > span > span > .MuiSwitch-thumb {
    background: #f2542d !important;
  }
  .MuiSwitch-track {
    width: 100%;
    height: 100%;
    opacity: 7.5 !important;
    z-index: -1;
    border-radius: 7px;
    background-color: white !important;
  }
  .tableHeads {
    padding: 0px 26px !important;
  }           
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #f86f00;
  }
  .pieChart > .recharts-wrapper > .recharts-legend-wrapper {
    right: 0 !important;
    top: 10px !important;
    left: auto !important;
  }
  .legendValue{
      font-size: 16px;
      font-family: 'Poppins', sans-serif;
      text-transform: none;
      color: rgb(89, 101, 121);
      font-weight: 500;
  
  }
  
  .link {
   text-decoration: none;
   color: #555252;
  }
  .link-diff {
    text-decoration: none;
    color: #dedede;
   }
  
     
      .iconColor:nth-child(even) {
        color: #dedede;
      }
   .MuiOutlinedInput-inputAdornedStart {
    padding-left: 0;
    color: black;
  }
  #imageBaseDiv>input {
    opacity: 0;
    width: 100%;
    height: 157%;
    border-radius: 100%;
    padding: -50px 0 0 0;
    position: relative;
    top: -32px;
    cursor: pointer;
  
  }
  .MuiSvgIcon-root {
    color: #F7931E;
  }
  #editField{
  padding-left: 13px;
    text-transform: capitalize;
  }
  
  #countryDropdown>div{
  border-radius: 10px !important;
  height: 41.16px;
  }
  #countryDropdown{
  height: 43.16px !important;
  }
  #react-select-2-listbox>div{
  background: white !important;
  }
  
  .MuiSvgIcon-root {
    color: #F7931E;
  }
  
  .pagination {
    display: flex;
  float: right;
  
    list-style: none;
    cursor: pointer;
  }
  
  .pagination a {
    padding: 10px;
    border-radius: 5px;
    color: #fff;
    border: none;
  }
  
  .pagination__link {
    font-weight: bold;
  }
  
  .pagination__link--active a {
    color:#F7931E;
    background: #80808070;
  }
  
  .pagination__link--disabled a {
    color: rgb(198, 197, 202);
    border: 1px solid #f1f1f1;
  }
    .MuiSvgIcon-root {
      color: #F7931E;
  }
  .noDataDiv {
    background: linear-gradient(180deg, rgba(143, 143, 143, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(137.09deg, rgba(42, 42, 42, 0.2) 0%, rgba(0, 164, 239, 0.14) 55.73%, rgba(42, 42, 42, 0.2) 100%);
   
    backdrop-filter: blur(2px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 8px;
    height: 400px;
    display: flex;
    justify-content: center;
  }
  .noDataDiv>div{
    margin-top: 182px;
  }
  .noDataDiv>p {
    color: #F2542D;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    margin-top: 182px;
  }
  
  
  
  .MuiTableCell-root{
    border-bottom:none !important;
  
  }
  .makeStyles-wrapper-2{
    min-height: calc(103vh - 83px) !important;
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    margin-bottom: 5vh;
    text-align: center;
  }
  
  
  .wrapper-class {
    border: 1px solid #ccc;
  }
  .editor-class {
    background-color: white;
    padding: 1rem;
    border: 1px solid #ccc;
  }
  .toolbar-class {
    border: 1px solid #ccc;
  }
  .editor-class{
  height: auto !important;
  }
  /* For Webkit (Chrome, Safari) */
::-webkit-scrollbar {
  width: 2px; /* width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background: #fff; /* color of the thumb (dragging part) */
  border-radius: 10px; /* rounded corners for the thumb */
}

::-webkit-scrollbar-track {
  background: #0B172F; /* color of the track (the scrollbar's background) */
}

.MuiButton-root {
  text-transform:none !important;
}


/* Define a keyframe animation named 'reveal' */
@keyframes reveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.reveal-text {
  width: 78%;
  margin: 0 auto;
}

/* Apply the animation to each letter with the class 'letter' */
.word {
  display: inline-block;
  opacity: 0; /* Start with 0 opacity */
  animation: reveal 1s ease-out forwards; /* Apply the 'reveal' animation with a duration of 1 second */
}

/* Add animation delay for each letter */
.letter:nth-child(1) {
  animation-delay: 0.1s; /* Delay for the first letter */
}

.letter:nth-child(2) {
  animation-delay: 0.2s; /* Delay for the second letter */
}

/* Add more styling and adjust animation delay as needed */



