/* styles.css or use styled-components */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 31px 10px;
  position: sticky;
  top: 0;
  z-index: 100;
  background: #fff;
}

.logo img {
  width: 100%;
  max-width: 200px;
  margin: 5px 5px 5px 0;
}
@media screen and (max-width: 480px) {
  .logo img {
    width: 100%;
    max-width: 140px;
    margin: 10px 5px 5px 0;
  }
}

.nav ul {
  list-style: none;
  display: flex;
  gap: 0px;
}

.nav li {
  color: inherit;
  font-size: 17px;
  font-style: normal;
  cursor: "pointer";
  font-weight: 700;
  transition: font-size 0.2s ease;
}
.nav li:hover {
  font-size: 18px;
  font-weight: 900;
  color: #2669f5;
}
.buy-now {
  padding: 13px 30px;
  border-radius: 20px;
  background: #2669f5;
  border: none;
  cursor: pointer;
  color: #fff;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  transition: background-color 0.2s ease, border-color 0.2s ease;
}
.buy-now:hover {
  background-color: transparent;
  border: 1px solid #2669f5;
  color: #2669f5;
}
.hamburger-css > svg {
  font-size: 47px;
  color: #2669f5;
}
@media (max-width: 767px) {
  .buy-now {
    font-size: 11px;
  }

  .hamburger-css > svg {
    font-size: 47px;
  }
  .signup-btn {
    display: none;
  }
}
@media (max-width: 1300px) {
  .nav li {
    font-size: 15px;
  }
}

@media (max-width: 899px) {
  .header {
    padding: 5px 10px;
  }
}

@media (min-width: 1000px) {
  .hamburger-css {
    display: none;
  }
}

@media (max-width: 1000px) {
  .nav {
    display: none;
    /* Hide navigation links on mobile */
  }

  .buy-button {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

@media (min-width: 899px) and (max-width: 1200px) {
  .header {
    padding: 5px 27px;
  }
  .logo img {
    max-width: 120px;
  }
}
